import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImgUploadInput from "../../components/ImgUploadInput";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useAuth } from "../../context/AuthContext";
import useAxios from "../../hooks/useAxios";
import SystemInfo from "../../util/SystemInfo";
import useOccupations from "../../hooks/useOccupations";
import check from "../../images/check.png";

const Register = (props) => {

    const [data, setData] = useState({
        name: '',
        credentialNumber: '',
        credential: null,
        phoneNumber: '',
        whatsapp: '',
        paypal: '',
        email: '',
        password: '',
        occupationsIds: []
    });

    const [currentOccupations, setCurrentOccupations] = useState([]);

    const { setAuthInfo } = useAuth();

    const [{ occupations, size, total, numberOfPages, error, loading }, getOccupations] = useOccupations();

    const [{ data: registerData, loading: registerLoading }, register] = useAxios({ url: '/auth/register-store', method: 'post' }, { manual: true, useCache: false });

    useEffect(() => {
        if (occupations.length > 0) {
            setCurrentOccupations((oldCurrentOccupations) => {
                return [...oldCurrentOccupations, ...occupations];
            });
        }
    }, [occupations])

    useEffect(() => {
        const selectButton = document?.getElementsByClassName?.('actions-btn')[0];
        selectButton?.setAttribute?.('type', 'button');
    }, [currentOccupations])

    useEffect(() => {
        if (registerData) {
            console.log(registerData);
        }
    }, [registerData])

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const onSignUp = (e) => {
        e.preventDefault();

        const formData = new FormData();

        const { occupationsIds, ...rest } = data;

        Object.keys(data).forEach((key, i) => {
            if (data[key]) {
                if (key === 'credential') {
                    formData.append(key, data[key], data[key].name);
                } else {
                    formData.append(key, data[key]);
                }
            }
        });

        occupationsIds.forEach((id, i) => {
            formData.append(`occupationIds[${i}]`, id)
        });

        register({ data: formData });
    }

    return (
        <div className="authincation p-meddle">
            <div className="container">
                <div className="row justify-content-center align-items-center mt-3 mb-4">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center" style={{ position: 'relative' }}>
                                            <Link to="/login" className="btn btn-primary" style={{ position: 'absolute', left: 0, height: 'auto' }}>
                                                <i className="ti-arrow-left" />
                                            </Link>
                                            <Link to="/login">
                                                <img style={{ width: '40%' }} src={SystemInfo.logoShort} alt="" />
                                            </Link>
                                        </div>
                                        <h4 className="text-center mb-4 ">Sign up your account</h4>
                                        {
                                            registerData ?
                                                <div className="text-center" style={{ marginBottom: '80px' }}>
                                                    <img style={{ width: '30%', marginBottom: '30px' }} src={check} alt="" />
                                                    <h1>
                                                        Very well, it has already been registered.
                                                    </h1>
                                                    <p>
                                                        We are validating the information sent by validating it, we will send you an email and you will be able to log in.
                                                    </p>
                                                </div>
                                                :
                                                <form onSubmit={onSignUp}>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1 ">
                                                            <strong>Name</strong>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="name"
                                                            name="name"
                                                            value={data?.name}
                                                            onChange={handleChange}
                                                            autoFocus
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1 ">
                                                            <strong>Email</strong>
                                                        </label>
                                                        <input
                                                            value={data?.email}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="email"
                                                            placeholder="hello@example.com"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1 ">
                                                            <strong>Password</strong>
                                                        </label>
                                                        <input
                                                            value={data?.password}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            type="password"
                                                            name="password"
                                                            placeholder="password"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Credential Number</strong>
                                                        </label>
                                                        <input
                                                            value={data?.credentialNumber}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="credentialNumber"
                                                            placeholder="credential number..."
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Credential</strong>
                                                        </label>
                                                        <ImgUploadInput
                                                            description="Credential"
                                                            name="credential"
                                                            change={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Phone Number</strong>
                                                        </label>
                                                        <input
                                                            value={data?.phoneNumber}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="phoneNumber"
                                                            placeholder="E: +584244699385"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Whatsapp</strong>
                                                        </label>
                                                        <input
                                                            value={data?.whatsapp}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="whatsapp"
                                                            placeholder="whatsapp"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Occupation</strong>
                                                        </label>
                                                        <div id="multiselect" className="text-center text-xl">
                                                            {
                                                                loading ?
                                                                    <h3>Loading...</h3>
                                                                    :
                                                                    currentOccupations.length > 0 ?
                                                                        <DropdownMultiselect
                                                                            options={currentOccupations}
                                                                            name="occupationsIds"
                                                                            optionKey="id"
                                                                            optionLabel="name"
                                                                            handleOnChange={(e) => { setData((oldData) => { return { ...oldData, occupationsIds: e } }) }} />
                                                                        :
                                                                        <h3>There are no occupations.</h3>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Paypal</strong>
                                                        </label>
                                                        <input
                                                            value={data?.paypal}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            type="email"
                                                            name="paypal"
                                                            placeholder="hello@example.com"
                                                        />
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button
                                                            disabled={registerLoading}

                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                        >
                                                            {
                                                                registerLoading ? 'Loading...' : 'Sign me up'
                                                            }
                                                        </button>
                                                    </div>
                                                </form>
                                        }
                                        <div className="new-account mt-3">
                                            <p className="">
                                                Already have an account?{" "}
                                                <Link className="text-primary" to="/login">
                                                    Sign in
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;