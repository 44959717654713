import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import InfiniteSelectList from "../../../components/InfiniteSelectList/InfiniteSelectList";
import useCategories from "../../../hooks/useCategories";
import useRecipeDifficulties from "../../../hooks/useRecipeDifficulties";
import useMealPeriods from "../../../hooks/useMealPeriods";
import update from "immutability-helper";
import { Button, Modal } from "react-bootstrap";
import useIngredients from "../../../hooks/useIngredients";
import SystemInfo from "../../../util/SystemInfo";
import useMeasurementUnits from "../../../hooks/useMeasurementUnits";
import ImgUploadInput from "../../../components/ImgUploadInput";

const RecipesCreate = () => {
  const { setLoading, setCustomAlert } = useFeedBack();

  const [categoriesFilters, setCategoriesFilters] = useState({
    page: 1,
    onlyParents: true,
  });

  const [showIngredientsModal, setShowIngredientsModal] = useState(false);

  const [ingredientsFilters, setIngredientsFilters] = useState({
    name: "",
    page: 1,
  });

  const navigate = useNavigate();

  const [data, setData] = useState({
    name: "",
    preparationTime: 30,
    description: "",
    shortDescription: "",
    isPremium: false,
    price: 0,
    numberOfDinners: 1,
    recipeDifficultyId: 1,
    categoryIds: [],
    mealPeriodIds: [],
    images: [],
    recipeVideos: [],
    recipeIngredients: [],
    recipeSteps: [],
  });

  const [currentCategories, setCurrentCategories] = useState([]);

  const [currentIngredients, setCurrentIngredients] = useState([]);

  const [
    { recipeDifficulties, loading: recipesDifficultiesLoading },
    getRecipeDifficulties,
  ] = useRecipeDifficulties();

  const [{ mealPeriods, loading: loadingMealPeriods }, getMealPeriods] =
    useMealPeriods();

  const [
    {
      ingredients,
      loading: ingredientsLoading,
      numberOfPages: ingredientsPages,
    },
    getIngredients,
  ] = useIngredients({ params: { ...ingredientsFilters } });

  const [
    { measurementUnits, loading: measurementUnitsLoading },
    getMeasurementUnits,
  ] = useMeasurementUnits();

  const [
    { categories, loading: categoriesLoading, numberOfPages: categoriesPages },
    getCategories,
  ] = useCategories({ params: { ...categoriesFilters } });

  const [{ data: createData, loading: createLoading }, create] = useAxios(
    { url: `/recipes`, method: "POST" },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    setCurrentIngredients([]);
  }, [ingredientsFilters.name]);

  useEffect(() => {
    if (categories.length > 0) {
      setCurrentCategories((oldCurrentCategories) => {
        return [...oldCurrentCategories, ...categories];
      });
    }
  }, [categories]);

  useEffect(() => {
    if (ingredients.length > 0) {
      setCurrentIngredients((oldCurrentIngredients) => {
        return [...oldCurrentIngredients, ...ingredients];
      });
    }
  }, [ingredients]);

  useEffect(() => {
    setLoading?.({
      show: createLoading,
      message: `Creating`,
    });
  }, [createLoading]);

  useEffect(() => {
    if (createData) {
      setCustomAlert((oldCustomAlert) => {
        return {
          ...oldCustomAlert,
          severity: "success",
          message: `The recipe was successfully created.`,
          show: true,
        };
      });
      navigate("/recipes");
    }
  }, [createData]);

  const handleSubmit = (e) => {
    e.preventDefault?.();

    const formData = new FormData();

    const {
      categoryIds,
      images,
      mealPeriodIds,
      recipeIngredients,
      recipeVideos,
      recipeSteps,
      ...rest
    } = data;

    Object.keys(rest).forEach((key, i) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });

    categoryIds.forEach((categoryId, i) => {
      if (categoryId) {
        formData.append(`categoryIds[${i}]`, categoryId);
      }
    });

    images.forEach((image, i) => {
      if (image) {
        formData.append(`images`, image, image.name);
      }
    });

    mealPeriodIds.forEach((periodId, i) => {
      if (periodId) {
        formData.append(`mealPeriodIds[${i}]`, periodId);
      }
    });

    recipeIngredients.forEach((ingredient, i) => {
      if (ingredient) {
        formData.append(
          `recipeIngredients[${i}][ingredientId]`,
          ingredient?.ingredientId
        );
        formData.append(
          `recipeIngredients[${i}][measurementUnitId]`,
          ingredient?.measurementUnitId
        );
        formData.append(`recipeIngredients[${i}][value]`, ingredient?.value);
      }
    });

    recipeVideos.forEach((video, i) => {
      if (video) {
        formData.append(
          `recipeVideos[${i}][isRecipeCover]`,
          video?.isCover ? true : false
        );
        formData.append(`recipeVideos[${i}][url]`, video?.url);
        formData.append(`recipeVideos[${i}][name]`, video?.name);
      }
    });

    recipeSteps.forEach((step, i) => {
      if (step) {
        formData.append(`recipeSteps[${i}][content]`, step?.content);
      }
    });

    create({ data: formData });
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      const value = data[e.target.name]?.includes(e.target.value);
      if (value) {
        const newValues = data[e.target.name]?.filter(
          (n) => n !== e.target.value
        );
        setData((oldData) => {
          return {
            ...oldData,
            [e.target.name]: newValues,
          };
        });
      } else {
        setData((oldData) => {
          return {
            ...oldData,
            [e.target.name]: [...data[e.target.name], e.target.value],
          };
        });
      }
      return;
    }

    setData((oldData) => {
      return {
        ...oldData,
        [e.target.name]:
          e.target.type === "file" ? e.target.files[0] : e.target.value,
      };
    });
  };

  const handleValue = (e) => {
    console.log(e);
    const value = data[e.target.name]?.includes(e.target.value);
    if (value) {
      const newValues = data[e.target.name]?.filter(
        (n) => n !== e.target.value
      );
      setData((oldData) => {
        return {
          ...oldData,
          [e.target.name]: newValues,
        };
      });
    } else {
      setData((oldData) => {
        return {
          ...oldData,
          [e.target.name]: [...data[e.target.name], e.target.value],
        };
      });
    }
  };

  const handleCategoriesEnd = () => {
    if (categoriesFilters?.page < categoriesPages) {
      setCategoriesFilters((oldCategoriesFilters) => {
        return {
          ...oldCategoriesFilters,
          page: oldCategoriesFilters?.page + 1,
        };
      });
    }
  };

  const handleArrayChange = (e, index, arrayName) => {
    var newArrayValues = [];
    if (e.target.name === "images") {
      newArrayValues = update(data?.[arrayName], {
        [index]: { $set: e.target.files[0] },
      });
    } else {
      newArrayValues = update(data?.[arrayName], {
        [index]: {
          [e.target.name]: {
            $set: e.target.type === "file" ? e.target.files[0] : e.target.value,
          },
        },
      });
    }
    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: newArrayValues,
      };
    });
  };

  const addVideo = () => {
    setData((oldData) => {
      return {
        ...oldData,
        recipeVideos: [
          ...oldData?.recipeVideos,
          { name: "", url: "", isCover: false },
        ],
      };
    });
  };

  const setVideoPortrait = (index) => {
    var newVideosArray = [];

    data?.recipeVideos?.forEach((video, i) => {
      newVideosArray = [
        ...newVideosArray,
        { ...video, isCover: i === index ? !video?.isCover : false },
      ];
    });

    setData((oldData) => {
      return {
        ...oldData,
        recipeVideos: newVideosArray,
      };
    });
  };

  const handleToggleIngredient = (ingredient) => {
    const ingredientIndex = data?.recipeIngredients?.findIndex(
      (currentIngredient) => currentIngredient?.ingredientId === ingredient?.id
    );
    const haveIngredient = data?.recipeIngredients[ingredientIndex];

    if (haveIngredient) {
      data?.recipeIngredients?.splice(ingredientIndex, 1);
      setData((oldData) => {
        return {
          ...oldData,
          recipeIngredients: data?.recipeIngredients,
        };
      });
      return;
    }

    setData((oldData) => {
      return {
        ...oldData,
        recipeIngredients: [
          ...oldData?.recipeIngredients,
          {
            ingredientId: ingredient?.id,
            measurementUnitId: 1,
            value: "",
            image: ingredient?.icon,
            name: ingredient?.name,
          },
        ],
      };
    });
  };

  const handleAddImage = () => {
    setData((oldData) => {
      return {
        ...oldData,
        images: [...oldData.images, null],
      };
    });
  };

  const handleAddStep = () => {
    setData((oldData) => {
      return {
        ...oldData,
        recipeSteps: [...oldData?.recipeSteps, { content: "" }],
      };
    });
  };

  const removeFromArray = (index, arrayName) => {
    data?.[arrayName]?.splice(index, 1);

    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: data?.[arrayName],
      };
    });
  };

  return (
    <div className="card" style={{ width: "100%", marginBottom: 200 }}>
      <div className="card-body">
        <div className="basic-form">
          <form onSubmit={handleSubmit}>
            <div className="row mb-5">
              <div className="form-group mb-3 col-md-6">
                <label>
                  <h5>Recipe Title</h5>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  autoFocus
                  value={data?.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>
                  <h5>Preparation Time (minutes) ⏰</h5>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="example: 30"
                  name="preparationTime"
                  value={data?.preparationTime}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>
                  <h5>Difficulty</h5>
                </label>
                <select
                  disabled={recipesDifficultiesLoading}
                  className="form-control"
                  name="recipeDifficultyId"
                  value={data?.recipeDifficultyId}
                  onChange={handleChange}
                >
                  {recipeDifficulties?.map((difficulty, i) => {
                    return (
                      <option value={difficulty?.id} key={i}>
                        {difficulty?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>
                  <h5>Number of Dinners 🍴</h5>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="example: 4"
                  name="numberOfDinners"
                  value={data?.numberOfDinners}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>
                  <h5>Types</h5>
                </label>
                <InfiniteSelectList
                  items={mealPeriods}
                  onReachEnd={() => null}
                  selectedValues={data?.mealPeriodIds}
                  labelAccessor={"name"}
                  keyValue={"id"}
                  name="mealPeriodIds"
                  onSelectValue={handleValue}
                  loadingMessage={"Loading..."}
                  withoutDropButton
                  loading={loadingMealPeriods}
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <label className="mb-1">
                  <h5>Categories</h5>
                </label>
                <InfiniteSelectList
                  items={currentCategories}
                  emptyMessage="No se encontraron categorias."
                  childrensSourcePath={`/categories`}
                  itemGetParam={`id`}
                  childrenGetParamName={`parentId`}
                  onReachEnd={handleCategoriesEnd}
                  selectedValues={data?.categoryIds}
                  labelAccessor={"name"}
                  keyValue={"id"}
                  name="categoryIds"
                  onSelectValue={handleValue}
                  loadingMessage={"Loading..."}
                  loading={categoriesLoading}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="d-block">
                  <h5>Is Premiun? 🌟</h5>
                </label>
                <Toggle
                  onChange={() => {
                    setData((oldData) => {
                      return { ...oldData, isPremium: !oldData?.isPremium };
                    });
                  }}
                  checked={data.isPremium}
                />
              </div>
              {data?.isPremium && (
                <div className="form-group mb-3 col-md-6">
                  <label>
                    <h5>Price ($)</h5>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="example: 30"
                    name="price"
                    value={data?.price}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label>Short Description</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="shortDescription..."
                    name="shortDescription"
                    value={data?.shortDescription}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <div>
                  <label>Description</label>
                  <textarea
                    name="description"
                    onChange={handleChange}
                    value={data?.description}
                    className="form-control"
                    style={{ height: 120 }}
                    rows={8}
                  ></textarea>
                </div>
              </div>
              <div
                className="col-md-12 mb-4 pb-2"
                style={{ borderBottom: "1px solid" }}
              >
                <h3>🎥 Videos</h3>
                <div className="row align-items-center">
                  {data?.recipeVideos?.map((video, i) => {
                    return (
                      <div className="col-md-4 my-4 text-center" key={i}>
                        <h4 style={{ margin: 0 }}>Is Cover?</h4>
                        <Toggle
                          onChange={(e) => {
                            setVideoPortrait(i);
                          }}
                          name="isCover"
                          value={video?.isCover}
                          checked={video?.isCover}
                        />
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="Name of the video..."
                          name="name"
                          value={video?.name}
                          onChange={(e) => {
                            handleArrayChange(e, i, "recipeVideos");
                          }}
                          autoFocus
                        />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="url of the video..."
                          name="url"
                          value={video?.url}
                          onChange={(e) => {
                            handleArrayChange(e, i, "recipeVideos");
                          }}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            removeFromArray(i, "recipeVideos");
                          }}
                          className="btn btn-xs btn-danger mt-1"
                        >
                          Remove
                        </button>
                      </div>
                    );
                  })}
                  <div className="col-md-4">
                    <button
                      onClick={addVideo}
                      type="button"
                      className="btn btn-primary"
                    >
                      Add Video
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12 mb-4 pb-2"
                style={{ borderBottom: "1px solid" }}
              >
                <h3>🍅 Ingredients</h3>
                <div className="row align-items-center">
                  {data?.recipeIngredients?.map((selectedIngredient, i) => {
                    return (
                      <div className="col-md-4 my-4 text-center" key={i}>
                        {selectedIngredient?.image && (
                          <img
                            style={{ width: "60%", borderRadius: 12 }}
                            src={`${SystemInfo?.api}${selectedIngredient?.image}`}
                          />
                        )}
                        <h4>{selectedIngredient?.name}</h4>
                        <select
                          className="form-control mb-2"
                          name="measurementUnitId"
                          value={selectedIngredient?.measurementUnitId}
                          onChange={(e) => {
                            handleArrayChange(e, i, "recipeIngredients");
                          }}
                          disabled={measurementUnitsLoading}
                        >
                          {measurementUnits?.map((measurementUnit, i) => {
                            return (
                              <option key={i} value={measurementUnit?.id}>
                                {measurementUnit?.name}
                              </option>
                            );
                          })}
                        </select>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="value..."
                          name="value"
                          value={selectedIngredient?.value}
                          onChange={(e) => {
                            handleArrayChange(e, i, "recipeIngredients");
                          }}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            removeFromArray(i, "recipeIngredients");
                          }}
                          className="btn btn-xs btn-danger mt-1"
                        >
                          Remove
                        </button>
                      </div>
                    );
                  })}
                  <div className="col-md-4">
                    <button
                      onClick={() => {
                        setShowIngredientsModal((oldValue) => !oldValue);
                      }}
                      type="button"
                      className="btn btn-primary"
                    >
                      Add Ingredient
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12 mb-4 pb-2"
                style={{ borderBottom: "1px solid" }}
              >
                <h3>📸 Images</h3>
                <div className="row align-items-center">
                  {data?.images?.map((image, i) => {
                    return (
                      <div className="col-md-4 my-4 text-center" key={i}>
                        <ImgUploadInput
                          description="drag or click"
                          name="images"
                          change={(e) => {
                            handleArrayChange(e, i, "images");
                          }}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            removeFromArray(i, "images");
                          }}
                          className="btn btn-xs btn-danger mt-1"
                        >
                          Remove
                        </button>
                      </div>
                    );
                  })}
                  <div className="col-md-4">
                    <button
                      onClick={handleAddImage}
                      type="button"
                      className="btn btn-primary"
                    >
                      Add Image
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-4 pb-2">
                <h3>👣 Steps</h3>
                <div className="row align-items-center">
                  {data?.recipeSteps?.map((step, i) => {
                    return (
                      <div className="col-md-12 my-1" key={i}>
                        <div className="row align-items-center">
                          <div className="col-md-10">
                            <h6>Step {i + 1}</h6>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder="Step..."
                              name="content"
                              value={step?.content}
                              onChange={(e) => {
                                handleArrayChange(e, i, "recipeSteps");
                              }}
                              autoFocus
                            />
                          </div>
                          <div className="col-md-2">
                            <button
                              clas
                              type="button"
                              onClick={() => {
                                removeFromArray(i, "recipeSteps");
                              }}
                              className="btn btn-xs btn-danger mt-1"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-md-4">
                    <button
                      onClick={handleAddStep}
                      type="button"
                      className="btn btn-primary"
                    >
                      Add Step
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 d-flex justify-content-end">
              <Link
                to={`#`}
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-danger mx-2"
              >
                Back
              </Link>
              <button type="submit" className="btn btn-primary mx-2">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal size="lg" className="fade" show={showIngredientsModal}>
        <Modal.Header>
          <Modal.Title>Add Ingredient:</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowIngredientsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Name of the ingredient..."
                autoFocus
                value={ingredientsFilters?.name}
                onChange={(e) => {
                  setIngredientsFilters((oldIngredientsFilters) => {
                    return { ...oldIngredientsFilters, name: e.target.value };
                  });
                }}
              />
            </div>
            {currentIngredients?.length > 0
              ? currentIngredients?.map((ingredient, i) => {
                  return (
                    <div
                      key={i}
                      className="ingredient-small col-md-1 text-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleToggleIngredient(ingredient);
                      }}
                    >
                      {data?.recipeIngredients
                        ?.map((ingredient2) => ingredient2?.ingredientId)
                        .includes(ingredient?.id) && <span>✔️</span>}
                      {ingredient?.icon && (
                        <img
                          style={{ maxWidth: "100%" }}
                          src={`${SystemInfo?.api}${ingredient?.icon}`}
                        />
                      )}
                      {ingredient?.name}
                    </div>
                  );
                })
              : !ingredientsLoading && (
                  <h3 className="text-center text-danger">
                    No hay ingredientes.
                  </h3>
                )}
            {ingredientsLoading && <div className="col-md-1">Loading...</div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowIngredientsModal(false)}
            variant="danger light"
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default RecipesCreate;
