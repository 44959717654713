import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import imgUrl from "../../../helpers/imgUrl";
import useAxios from "../../../hooks/useAxios";

const OrdersDetails = () => {

    const { user } = useAuth();

    const { id } = useParams();

    const [currentOrder, setCurrentOrder] = useState(null);

    const [{ data, loading, error }, getOrder] = useAxios({ url: `/orders/${id}` }, { useCache: false });

    const [{ error: printError, loading: printLoading }, printOrder] = useAxios({ url: `/orders/${id}/pdf`, responseType: 'blob' }, { manual: true, useCache: false });

    useEffect(() => {
        if (data) {
            setCurrentOrder(data);
            console.log(data);
        }
    }, [data])

    const handleBlobResponse = (blobResponse) => {
        const fileBlobUrl = URL.createObjectURL(blobResponse);
        const aToDownload = document.getElementById('downloadLink');
        aToDownload.href = fileBlobUrl;
        aToDownload?.click();
        window.URL.revokeObjectURL(fileBlobUrl);
    }

    const handlePrint = () => {
        printOrder().then((response) => {
            console.log(response)
            handleBlobResponse(response?.data);
        });
    }

    return (
        <div>
            <a id="downloadLink" style={{ display: 'none' }} download={`order-${currentOrder?.id}`}></a>
            <div className="row">
                <div className="col-md-6">
                    <h1>
                        Orders Details
                    </h1>
                    <p>
                        Hi, the order has been <span style={{ color: currentOrder?.orderStatus?.color, textTransform: "capitalize" }}>{currentOrder?.orderStatus?.name}.</span>
                    </p>
                </div>
                <div className="col-md-6 text-end">
                    <button disabled={printLoading} onClick={handlePrint} className="btn btn-danger">
                        {
                            printLoading ?
                                'Loading...'
                                :
                                'Print'
                        }
                    </button>
                </div>
            </div>
            <br /><br />
            <table
                id="example5"
                className="display dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
            >
                <tbody>
                    <tr role="row" className="odd border-bottom">
                        <td className="sorting py-5">
                            <p>
                                <b> Order Date </b>
                            </p>
                            {currentOrder?.createdAt && format(new Date(currentOrder?.createdAt), 'dd-MM-yyyy')}
                        </td>
                        <td className="sorting py-5">
                            <p>
                                <b>Order Number</b>
                            </p>
                            {currentOrder?.id}
                        </td>
                        <td className="sorting py-5">
                            <p>
                                <b>Payment Method</b>
                            </p>
                            {currentOrder?.payment?.paymentMethod?.name || '--'}
                        </td>
                        <td className="sorting py-5">
                            <p>
                                <b>
                                    Order Client
                                </b>
                            </p>
                            {currentOrder?.client?.name}
                        </td>
                    </tr>
                    {
                        currentOrder?.orderItems?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="py-4">
                                        <img src={imgUrl(item?.image)} style={{ width: 60, height: 60, borderRadius: '10px' }} />
                                    </td>
                                    <td className="py-4">
                                        <Link to={`/${item?.type}s/${item?.id}`}>
                                            <b>
                                                <span className="text-capitalize">
                                                    {item?.type}
                                                </span>
                                                {`:  ${item?.name}`}
                                            </b>
                                        </Link>
                                    </td>
                                    <td className="py-4">
                                        Qty {item?.quantity}
                                    </td>
                                    <td className="py-4 text-end">
                                        {item?.total ? `$${item?.total}` : 'Free'}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr className="border-top">
                        <td colSpan={2}>
                            <b>Subtotal</b>
                        </td>
                        <td colSpan={2} className="text-end">
                            {currentOrder?.total ? `$${currentOrder?.total}` : 'Free'}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <b>Taxes</b>
                        </td>
                        <td colSpan={2} className="text-end">
                            {currentOrder?.taxes ? `$${currentOrder?.taxes}` : '$0'}
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td colSpan={2}>
                            <b>Discount</b>
                        </td>
                        <td colSpan={2} className="text-end">
                            {currentOrder?.discount ? `$${currentOrder?.discount}` : '$0'}
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td colSpan={2}>
                            <b>Total</b>
                        </td>
                        <td colSpan={2} className="text-end">
                            {currentOrder?.total ? `$${currentOrder?.total}` : '$0'}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default OrdersDetails;