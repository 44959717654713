import { Link } from "react-router-dom";

const RenderRatingProduct = ({ value }) => {
    return (
        <div>
            {
                value?.recipe &&
                <Link to={`/recipes/${value?.recipe?.id}`}>
                    {value?.recipe?.name}
                </Link>
            }

            {
                value?.combo &&
                <Link to={`/combos/${value?.combo?.id}`}>
                    {value?.combo?.name}
                </Link>
            }

            {
                value?.plan &&
                <Link to={`/plans/${value?.plan?.id}`}>
                    {value?.plan?.name}
                </Link>
            }
        </div>
    )
}

export default RenderRatingProduct;