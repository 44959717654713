import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import RadialDount from "../../components/RadialDount";
import ReservationChart from "../../components/ReservationChart";
import { useTheme } from "../../context/ThemeContext";
import useAxios from "../../hooks/useAxios";


const Dashboard = () => {

    const { chnageSidebarColor, colors, changePrimaryColor, setDemoTheme } = useTheme();

    const [{ data: summariesData, loading: summariesLoading }] = useAxios({ url: `/summaries/dashboard` }, { useCache: false });

    useEffect(() => {
        if (summariesData) {
            console.log(summariesData);
        }
    }, [summariesData])

    return (
        <div>
            <div className="row">
                <div className="col-xl-3 col-sm-6">
                    <div className="card gradient-1 card-bx">
                        <div className="card-body d-flex align-items-center">
                            <div className="me-auto text-white">
                                <h2 className="text-white">{summariesLoading ? 'loading' : summariesData?.clientsCount}</h2>
                                <span className="fs-18">Customers</span>
                            </div>
                            <i className="flaticon-381-user-9 text-white" style={{ fontSize: 40 }}></i>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card gradient-2 card-bx">
                        <div className="card-body d-flex align-items-center">
                            <div className="me-auto text-white">
                                <h2 className="text-white">{summariesLoading ? 'loading' : summariesData?.recipesCount}</h2>
                                <span className="fs-18">Recipes</span>
                            </div>
                            <i className="fa fa-shopping-basket text-white" style={{ fontSize: 40 }}></i>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card gradient-3 card-bx">
                        <div className="card-body d-flex align-items-center">
                            <div className="me-auto text-white">
                                <h2 className="text-white">{summariesLoading ? 'loading' : summariesData?.plansCount}</h2>
                                <span className="fs-18">Plans</span>
                            </div>
                            <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M36.25 9.66665V7.24998C36.25 5.91598 37.3327 4.83331 38.6667 4.83331C40.0007 4.83331 41.0833 5.91598 41.0833 7.24998V9.66665C46.4242 9.66665 50.75 13.9949 50.75 19.3333V43.5C50.75 48.8384 46.4242 53.1666 41.0833 53.1666C34.1741 53.1666 23.8283 53.1666 16.9167 53.1666C11.5782 53.1666 7.25 48.8384 7.25 43.5V19.3333C7.25 13.9949 11.5782 9.66665 16.9167 9.66665V7.24998C16.9167 5.91598 17.9993 4.83331 19.3333 4.83331C20.6673 4.83331 21.75 5.91598 21.75 7.24998V9.66665H36.25ZM45.9167 29H12.0833V43.5C12.0833 46.168 14.2487 48.3333 16.9167 48.3333H41.0833C43.7537 48.3333 45.9167 46.168 45.9167 43.5V29ZM33.5748 37.8329L36.9822 34.5172C37.9392 33.5868 39.469 33.6086 40.3994 34.5656C41.3298 35.5202 41.3081 37.0523 40.3535 37.9827L35.3848 42.8161C34.4955 43.6788 33.1011 43.732 32.1513 42.9393L29.4302 40.6677C28.4055 39.8146 28.2677 38.2896 29.1232 37.265C29.9763 36.2403 31.5012 36.1026 32.5259 36.9581L33.5748 37.8329ZM41.0833 14.5V16.9166C41.0833 18.2506 40.0007 19.3333 38.6667 19.3333C37.3327 19.3333 36.25 18.2506 36.25 16.9166V14.5H21.75V16.9166C21.75 18.2506 20.6673 19.3333 19.3333 19.3333C17.9993 19.3333 16.9167 18.2506 16.9167 16.9166V14.5C14.2487 14.5 12.0833 16.6629 12.0833 19.3333V24.1666H45.9167V19.3333C45.9167 16.6629 43.7537 14.5 41.0833 14.5Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card gradient-4 card-bx">
                        <div className="card-body d-flex align-items-center">
                            <div className="me-auto text-white">
                                <h2 className="text-white">{summariesLoading ? 'loading' : summariesData?.combosCount}</h2>
                                <span className="fs-18">Combos</span>
                            </div>
                            <i className="flaticon-381-dashboard text-white" style={{ fontSize: 40 }}></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xl-12">
                            <div className="card text-center" style={{ height: 'auto' }}>
                                <div className="card-body">
                                    <div id="radialChart" className="radialChart">
                                        <RadialDount />
                                    </div>
                                    <h2>785</h2>
                                    <span className="fs-16 text-black">Finished orders</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xl-12">
                            <div className="card" style={{ height: 'auto' }}>
                                <div className="card-header border-0 pb-0">
                                    <h4 className="mb-0">Orders</h4>
                                </div>
                                <div className="card-body">
                                    <div className="progress mb-4" style={{ height: "13px" }}>
                                        <div className="progress-bar gradient-5 progress-animated" style={{ width: "55%", height: "13px" }} ></div>
                                    </div>
                                    <div className="progress mb-4" style={{ height: "13px" }}>
                                        <div className="progress-bar gradient-6 progress-animated" style={{ width: "90%", height: "13px" }}></div>
                                    </div>
                                    <div className="progress default-progress" style={{ height: "13px" }}>
                                        <div className="progress-bar gradient-7 progress-animated" style={{ width: "80%", height: "13px" }}></div>
                                    </div>
                                    <div className="d-flex mt-4 progress-bar-legend align-items-center justify-content-between">
                                        <div className="d-flex">
                                            <span className="marker gradient-5"></span>
                                            <div>
                                                <p className="status">Pending</p>
                                                <span className="result">234</span>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <span className="marker gradient-6"></span>
                                            <div>
                                                <p className="status">Done</p>
                                                <span className="result">65</span>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <span className="marker gradient-7"></span>
                                            <div>
                                                <p className="status">Finish</p>
                                                <span className="result">763</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-sm-12">
                    <div className="card">
                        <div className="card-header border-0 d-sm-flex d-block">
                            <div className="me-auto mb-sm-0 mb-3">
                                <h4 className="card-title mb-2">Recipes</h4>
                                <span>No. of Free and Paid Recipes</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex me-5">
                                    <h3 className="mb-0 me-2">549</h3>
                                    <span>Free</span>
                                </div>
                                <div className="d-flex me-3">
                                    <h3 className="mb-0 me-2">327</h3>
                                    <span>Payment</span>
                                </div>
                                <Dropdown className="dropdown">
                                    <Dropdown.Toggle as="div" className="btn-link i-false" >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu">
                                        <Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
                                        <Dropdown.Item className="dropdown-item">Edit</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="card-body">
                            <div id="reservationChart" className="reservationChart">
                                <ReservationChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;