import ActionDropdown from "../../ActionDropdown";
import ImageAndName from "../../ImageAndName";
import TableCheck from "../TableCheck";

const ShortPlansColumns = [
    {
        Label: () => 'Check',
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Name',
        Component: ImageAndName
    },
    {
        Label: () => 'Categories',
        Component: ({ value }) => value?.categories?.map(category => category?.name)?.join?.(', ')
    },
    {
        Label: () => 'Price',
        Component: ({ value }) => `${value?.price}$`
    }
];

export default ShortPlansColumns;

