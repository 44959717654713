import { Link } from "react-router-dom";
import DateFormatter from "../../DateFormatter";

const OrdersColumns = [
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Client',
        Component: ({ value }) => value.client?.name,
    },
    {
        Label: () => 'Total',
        Component: ({ value }) => value?.total ? `$${value?.total}` : 'Free',
    },
    {
        Label: () => 'Estatus',
        Component: ({ value }) => <p style={{ color: value?.orderStatus?.color, textTransform: 'capitalize', margin: 0 }}>{value?.orderStatus?.name}</p>,
    },
    {
        Label: () => 'Created at',
        Component: ({ value }) => <DateFormatter value={value.createdAt} dateFormat="dd-MM-yyyy hh:mm:ss" />,
    },
    {
        Label: () => 'Actions',
        Component: ({ value }) => <Link to={`/orders/${value.id}`}>View Details</Link>
    }
];

export default OrdersColumns;

