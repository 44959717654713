import CutText from "../../../util/CutText";
import ActionDropdown from "../../ActionDropdown";
import DateFormatter from "../../DateFormatter";
import RenderCommentable from "../../RenderCommentable";
import TableCheck from "../TableCheck";

const CommentsColumns = [
    {
        Label: TableCheck,
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Client Name',
        Component: ({ value }) => `${value?.name ? value?.name : 'Guest'}`
    },
    {
        Label: () => 'message',
        Component: ({ value }) => <CutText text={value?.comment} length={15} />
    },
    {
        Label: () => 'Your answer',
        Component: ({ value }) => value?.answer ? <CutText text={value?.answer} length={15} /> : '--'
    },
    {
        Label: () => `What's Commented`,
        Component: RenderCommentable
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat="dd-MM-yyyy" />
    },
    {
        Label: () => 'Actions',
        Component: ActionDropdown
    }
];

export default CommentsColumns;

