import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import InfiniteSelectList from "../../../components/InfiniteSelectList/InfiniteSelectList";
import useCategories from "../../../hooks/useCategories";
import update from 'immutability-helper';
import ImgUploadInput from "../../../components/ImgUploadInput";
import { Button, ListGroup, Modal, Tab } from "react-bootstrap";
import clsx from "clsx";
import useMealPeriods from "../../../hooks/useMealPeriods";
import useRecipes from "../../../hooks/useRecipes";
import CustomTable from "../../../components/CustomTable/CustomTable";
import RecipesModalColumns from "../../../components/CustomTable/Columns/RecipesModalColumns";
import SystemInfo from "../../../util/SystemInfo";
import swal from "sweetalert";
import ImageCrudComponent from "../../../components/ImageCrudComponent";

const PlansEdit = () => {

    const { id } = useParams();

    const { setLoading, setCustomAlert, setDisableAutoScroll } = useFeedBack();

    const [categoriesFilters, setCategoriesFilters] = useState({
        page: 1,
        onlyParents: true
    });

    const [recipesFilters, setRecipesFilters] = useState({
        page: 1,
        name: ''
    });

    const navigate = useNavigate();

    const [{ data: dataToUpdate, loading: dataToUpdateLoading }, getData] = useAxios({ url: `/plans/${id}` }, { useCache: false });

    const [{ mealPeriods, loading: mealPeriodsLoading }, getMealPeriods] = useMealPeriods();

    const [{ recipes, loading: recipesLoading, numberOfPages: recipesPages, total }, getRecipes] = useRecipes({ axiosConfig: { params: { ...recipesFilters } } });

    const [showRecipesModal, setShowRecipesModal] = useState(false);

    const [selectedMealPeriod, setSelectedMealPeriod] = useState(null);

    const [data, setData] = useState({
        name: '',
        description: '',
        price: 0,
        categoryIds: [],
        planWeeks: []
    });

    const [previeImages, setImagesPreview] = useState([]);

    const [selectedDay, setSelectedDay] = useState(null);

    const [currentCategories, setCurrentCategories] = useState([]);

    const [{ categories, loading: categoriesLoading, numberOfPages: categoriesPages }, getCategories] = useCategories({ params: { ...categoriesFilters } });

    const [{ data: updateData, loading: updateLoading }, update] = useAxios({ url: `/plans/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (dataToUpdate) {
            const { fullPlanDays, images, ...rest } = dataToUpdate;

            var weeks = [];

            var week = [];

            fullPlanDays?.forEach((planday, i) => {
                if (week.length === 7) {
                    weeks = [...weeks, {
                        week: weeks?.length + 1,
                        days: week
                    }];

                    week = [];
                    week.push(planday);
                } else {
                    week.push(planday);
                }
            });

            weeks = [...weeks, {
                week: weeks?.length + 1,
                days: week
            }];

            setData((oldData) => {
                return {
                    ...oldData,
                    name: rest?.name,
                    description: rest?.description,
                    price: rest?.price,
                    categoryIds: rest?.categories?.map(category => category?.id),
                    planWeeks: weeks
                }
            })
            setImagesPreview(images?.map((image) => {
                return {
                    id: image?.id,
                    path: `${SystemInfo?.api}${image?.path}`
                }
            }));
        }
    }, [dataToUpdate])

    useEffect(() => {
        setDisableAutoScroll(true);
    }, []);

    useEffect(() => {
        setRecipesFilters((oldRecipesFilters) => {
            return {
                ...oldRecipesFilters,
                page: 1
            }
        });
    }, [recipesFilters.name]);

    useEffect(() => {
        if (categories.length > 0) {
            setCurrentCategories((oldCurrentCategories) => {
                return [...oldCurrentCategories, ...categories]
            })
        }
    }, [categories]);

    useEffect(() => {
        setLoading?.({
            show: updateLoading,
            message: `Updating`
        });
    }, [updateLoading]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert(oldCustomAlert => {
                return {
                    ...oldCustomAlert,
                    severity: 'success',
                    message: `The plan was successfully updated.`,
                    show: true
                }
            });
            navigate('/plans');
        }
    }, [updateData]);

    useEffect(() => {
        setLoading?.({
            show: dataToUpdateLoading,
            message: 'Loading'
        })
    }, [dataToUpdateLoading])

    const handleSubmit = (e) => {
        e.preventDefault?.();

        swal({
            title: "¿are you sure?",
            text: "¿Please check that all information is complete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                onConfirm?.();
            } else {

            }
        });
    }

    const onConfirm = () => {
        const { planWeeks, ...rest } = data;

        var planDays = [];
        var lastDay = 0;
        planWeeks?.forEach((week, weeki) => {
            week.days?.forEach((day, dayi) => {
                day?.mealPeriods?.forEach((period, periodi) => {
                    period?.recipes?.forEach((recipe, recipei) => {
                        planDays.push({
                            day: day?.day,
                            mealPeriodId: period?.id,
                            recipeId: recipe?.id
                        });
                    })
                });
                lastDay = day?.day;
            })
        });



        update({
            data: {
                ...rest,
                numberOfDays: lastDay,
                planDays
            }
        });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const handleValue = (e) => {
        const value = data[e.target.name]?.includes(e.target.value);
        if (value) {
            const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
            setData((oldData) => {
                return {
                    ...oldData,
                    [e.target.name]: newValues
                }
            });
        } else {
            setData((oldData) => {
                return {
                    ...oldData,
                    [e.target.name]: [...data[e.target.name], e.target.value]
                }
            });
        }
    }

    const handleCategoriesEnd = () => {
        if (categoriesFilters?.page < categoriesPages) {
            setCategoriesFilters((oldCategoriesFilters) => {
                return {
                    ...oldCategoriesFilters,
                    page: oldCategoriesFilters?.page + 1
                }
            });
        }
    }

    const handleAddWeek = () => {
        const value = data?.planWeeks.length;

        setData?.((oldData) => {
            return {
                ...oldData,
                planWeeks: [
                    ...oldData.planWeeks,
                    {
                        week: value + 1,
                        days: [
                            ...Array.from(Array(7).keys()).map((day) => {
                                return {
                                    day: (value + 1) > 1 ? (value * 7) + (day + 1) : (day + 1),
                                    mealPeriods: [
                                        ...mealPeriods.map((period) => {
                                            return {
                                                ...period,
                                                recipes: []
                                            }
                                        })
                                    ]
                                }
                            })
                        ]
                    }
                ]
            }
        })
    }

    const handleRemoveWeek = () => {

        if (data?.planWeeks?.length > 2) {
            data?.planWeeks?.pop();
        } else {
            return;
        }

        setData?.((oldData) => {
            return {
                ...oldData,
                planWeeks: [
                    ...data?.planWeeks
                ]
            }
        })
    }

    const dayHaveRecipes = (day) => {
        var dayRecipes = day?.mealPeriods.filter((period) => {
            if (period.recipes?.length > 0) {
                return period;
            }
        });


        if (dayRecipes.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const handleDay = (day, index, weeki) => {
        setSelectedDay({ ...day, index: index, weeki: weeki });
    }

    const handleSelectValue = (value) => {
        const res = periodHasRecipe(value?.id);

        var newArrayValues = [];

        var mealPeriodsForDaySelected = [];

        var recipesForSelectedMealPeriods = [];

        if (res?.have) {

            newArrayValues = update(data?.planWeeks, { [selectedDay?.weeki]: { ['days']: { [selectedDay?.index]: { ['mealPeriods']: { [selectedMealPeriod?.index]: { ['recipes']: { $splice: [[res?.index, 1]] } } } } } } });

            mealPeriodsForDaySelected = update(selectedDay?.mealPeriods, { [selectedMealPeriod?.index]: { ['recipes']: { $splice: [[res?.index, 1]] } } });

            recipesForSelectedMealPeriods = update(selectedMealPeriod?.recipes, { $splice: [[res?.index, 1]] });

            setSelectedMealPeriod((oldSelectedMealPeriods) => {
                return {
                    ...oldSelectedMealPeriods,
                    recipes: recipesForSelectedMealPeriods
                }
            });

            setSelectedDay((oldSelectedDay) => {
                return {
                    ...oldSelectedDay,
                    mealPeriods: mealPeriodsForDaySelected
                }
            });

            setData((oldData) => {
                return {
                    ...oldData,
                    planWeeks: newArrayValues
                }
            });

        } else {

            newArrayValues = update(data?.planWeeks, { [selectedDay?.weeki]: { ['days']: { [selectedDay?.index]: { ['mealPeriods']: { [selectedMealPeriod?.index]: { ['recipes']: { $push: [value] } } } } } } });

            mealPeriodsForDaySelected = update(selectedDay?.mealPeriods, { [selectedMealPeriod?.index]: { ['recipes']: { $push: [value] } } });

            recipesForSelectedMealPeriods = update(selectedMealPeriod?.recipes, { $push: [value] });

            setSelectedMealPeriod((oldSelectedMealPeriods) => {
                return {
                    ...oldSelectedMealPeriods,
                    recipes: recipesForSelectedMealPeriods
                }
            });

            setSelectedDay((oldSelectedDay) => {
                return {
                    ...oldSelectedDay,
                    mealPeriods: mealPeriodsForDaySelected
                }
            });

            setData((oldData) => {
                return {
                    ...oldData,
                    planWeeks: newArrayValues
                }
            });
        }
    }

    const handlePageChange = (page) => {
        if (page < 11 && page > 0) {
            setRecipesFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const periodHasRecipe = ($id) => {
        const itemIndex = selectedMealPeriod?.recipes?.findIndex(x => x.id === $id);

        if (itemIndex >= 0) {
            return { have: true, index: itemIndex };
        } else {
            return false;
        }
    }

    return (
        <div className="card" style={{ width: '100%', marginBottom: 200 }}>
            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">

                            <div className="form-group mb-3 col-md-6">
                                <label>
                                    <h5>
                                        Plan Title
                                    </h5>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    autoFocus
                                    value={data?.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>
                                    <h5>
                                        Price ($)
                                    </h5>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="example: 30"
                                    name="price"
                                    value={data?.price}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-12 form-group mb-3">
                                <label className="mb-1">
                                    <h5>Categories</h5>
                                </label>
                                <InfiniteSelectList
                                    items={currentCategories}
                                    emptyMessage="No se encontraron categorias."
                                    childrensSourcePath={`/categories`}
                                    itemGetParam={`id`}
                                    childrenGetParamName={`parentId`}
                                    onReachEnd={handleCategoriesEnd}
                                    selectedValues={data?.categoryIds}
                                    labelAccessor={'name'}
                                    keyValue={'id'}
                                    name="categoryIds"
                                    onSelectValue={handleValue}
                                    loadingMessage={'Loading...'}
                                    loading={categoriesLoading}
                                />
                            </div>
                            <div className="col-md-12 mb-4">
                                <div>
                                    <label>
                                        Description
                                    </label>
                                    <textarea name="description" onChange={handleChange} value={data?.description} className="form-control" style={{ height: 120 }} rows={8}></textarea>
                                </div>
                            </div>
                            <ImageCrudComponent
                                title={`📸 Images`}
                                modelName={"plans"}
                                defaultImages={previeImages}
                                ownerId={id}
                            />
                            <div className="col-md-6">
                                <h3 className="text-center">
                                    Weeks
                                </h3>
                                {
                                    mealPeriodsLoading ?
                                        <h4 className="text-center">Loading...</h4>
                                        :
                                        data?.planWeeks?.map((week, i) => {
                                            return (
                                                <div key={i} className="mb-3">
                                                    <h3>Week {week.week}</h3>
                                                    <div className="d-flex">
                                                        {
                                                            week?.days?.map((day, dayI) => {
                                                                return (
                                                                    <div key={dayI} style={{ width: '100%' }} >
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => { handleDay(day, dayI, i) }}
                                                                            style={{ position: 'relative' }}
                                                                            className={clsx(["btn"], {
                                                                                'btn-success': selectedDay?.day === day?.day
                                                                            })}
                                                                        >
                                                                            Day {day?.day}

                                                                            <i style={{ position: 'absolute', top: 0, right: 0 }} className={clsx(["flaticon-381-check-circle-1"], {
                                                                                "text-success": dayHaveRecipes(day) ? true : false
                                                                            })}></i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                                <div className="text-center mt-4">
                                    <button disabled={data?.planWeeks?.length > 2 ? false : true} type="button" onClick={handleRemoveWeek} className="btn btn-danger mx-1">Remove Week</button>
                                    <button type="button" onClick={handleAddWeek} className="btn btn-primary mx-1">Add Week</button>
                                </div>
                            </div>
                            {
                                selectedDay &&
                                <div className="col-md-6">
                                    <h4 className="text-center mt-3">
                                        Day {selectedDay?.day}
                                    </h4>
                                    <Tab.Container>
                                        <ListGroup style={{ display: 'flex', flexDirection: 'row', maxWidth: "100%", overflowX: 'auto' }} className="mb-4" id="list-tab">
                                            {
                                                selectedDay?.mealPeriods?.map((period, i) => {
                                                    return (
                                                        <ListGroup.Item
                                                            style={{ position: 'relative', borderRadius: '10px 10px 0px 0px', border: '1px solid gray', textAlign: 'center' }}
                                                            onClick={() => setSelectedMealPeriod({ ...period, index: i })}
                                                            key={i}
                                                            action
                                                            href={`#day-${selectedDay?.day}-period-${period?.id}`}
                                                        >
                                                            {period?.name}
                                                            <span style={{ position: 'absolute', top: 0, right: 0 }} className="badge badge-danger text-white badge-sm float-end">
                                                                {period?.recipes?.length}
                                                            </span>
                                                        </ListGroup.Item>
                                                    )
                                                })
                                            }
                                        </ListGroup>
                                        <Tab.Content>
                                            {
                                                selectedDay?.mealPeriods?.map?.((period, i) => {
                                                    return (
                                                        <Tab.Pane style={{ marginTop: '10px' }} eventKey={`#day-${selectedDay?.day}-period-${period?.id}`} key={i}>
                                                            <div className="row">
                                                                {
                                                                    period?.recipes?.map?.((recipe, recipeI) => {
                                                                        return (
                                                                            <div style={{ position: 'relative' }} className="card-recipes-small col-md-4 text-center" key={recipeI}>
                                                                                <img className="rounded" style={{ maxWidth: '100%' }} src={`${SystemInfo?.api}${recipe?.recipeImages?.[0]?.path}`} alt="" />
                                                                                {recipe?.name}
                                                                                <div className="delete-overlay animate__animated animate__fadeInUp animate__faster">
                                                                                    <button onClick={(e) => { handleSelectValue(recipe) }} type="button" className="btn btn-danger btn-sm m-auto">
                                                                                        <i className="flaticon-381-close"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="text-center">
                                                                <button onClick={() => { setShowRecipesModal(true) }} type="button" className="btn btn-primary">Add Recipe</button>
                                                            </div>
                                                        </Tab.Pane>
                                                    )
                                                })
                                            }
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            }
                        </div>
                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Back
                            </Link>
                            <button type="submit" className="btn btn-primary mx-2">
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal size="lg" className="fade" show={showRecipesModal}>
                <Modal.Header>
                    <Modal.Title>Add Recipe:</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setShowRecipesModal(false)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <CustomTable
                        onSelectValue={handleSelectValue}
                        loading={recipesLoading}
                        selectedValues={selectedMealPeriod?.recipes?.map(recipe => recipe?.id)}
                        pages={recipesPages}
                        total={total}
                        values={recipes}
                        currentPage={recipesFilters.page}
                        collumns={RecipesModalColumns}
                        changePage={handlePageChange}
                        withoutGlobalActions
                        variant="simple"
                        hideSelectAll
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setShowRecipesModal(false)}
                        variant="danger light"
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default PlansEdit;