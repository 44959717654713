import { useCallback, useRef } from "react";
import InfiniteSelectListItem from "./InfiniteSelectListItem";

const InfiniteSelectList = ({
    items = [],
    childrensSourcePath = '',
    itemGetParam = `id`,
    keyValue = 'id',
    childrenGetParamName = 'id',
    onReachEnd = () => null,
    selectedValues = [],
    labelAccessor = 'name',
    name = '',
    onSelectValue = () => null,
    containerProps,
    emptyMessage = 'has no records.',
    deep = 0,
    loadingMessage = 'Loading...',
    withoutDropButton = false,
    loading = false
}) => {

    const observer = useRef();

    const lastValueRef = useCallback((value) => {
        if (observer?.current) observer?.current?.disconnect?.();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                onReachEnd();
            }
        })
        if (value) observer?.current?.observe?.(value)
    }, [items]);

    return (
        <ul className="custom-scrollbar scrollbar-primary" style={{ marginLeft: `${deep}0px`, maxHeight: '300px', overflowY: 'auto' }} {...containerProps}>
            {
                !loading ?
                    items?.length > 0 ?
                        items?.map((value, i) => {
                            return <InfiniteSelectListItem
                                ref={i + 1 === items.length ? lastValueRef : null}
                                key={i}
                                value={value}
                                childrensSourcePath={childrensSourcePath}
                                itemGetParam={itemGetParam}
                                childrenGetParamName={childrenGetParamName}
                                selectedValues={selectedValues}
                                labelAccessor={labelAccessor}
                                name={name}
                                keyValue={keyValue}
                                onSelect={onSelectValue}
                                emptyMessage={emptyMessage}
                                deep={deep}
                                loadingMessage={loadingMessage}
                                withoutDropButton={withoutDropButton}
                            />
                        })
                        :
                        emptyMessage
                    :
                    loadingMessage
            }
        </ul>
    )
}

export default InfiniteSelectList;