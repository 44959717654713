import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import InfiniteSelectList from "../../../components/InfiniteSelectList/InfiniteSelectList";
import useCategories from "../../../hooks/useCategories";
import update from 'immutability-helper';
import ImgUploadInput from "../../../components/ImgUploadInput";
import { Button, Modal } from "react-bootstrap";
import useMealPeriods from "../../../hooks/useMealPeriods";
import useRecipes from "../../../hooks/useRecipes";
import swal from "sweetalert";
import usePlans from "../../../hooks/usePlans";
import useComboPurposes from "../../../hooks/useComboPurposes";
import { useAuth } from "../../../context/AuthContext";
import CustomTable from "../../../components/CustomTable/CustomTable";
import ShortPlansColumns from "../../../components/CustomTable/Columns/ShortPlansColumns";
import RecipesModalColumns from "../../../components/CustomTable/Columns/RecipesModalColumns";

const CombosCreate = () => {

    const { user } = useAuth();

    const { setLoading, setCustomAlert, setDisableAutoScroll } = useFeedBack();

    const [showPlans, setShowPlans] = useState(false);

    const [showRecipes, setShowRecipes] = useState(false);

    const [categoriesFilters, setCategoriesFilters] = useState({
        page: 1,
        onlyParents: true
    });

    const [recipesFilters, setRecipesFilters] = useState({
        page: 1,
        sellerId: user?.sellerId
    });

    const [plansFilters, setPlansFilters] = useState({
        page: 1,
        sellerId: user?.sellerId
    });

    const navigate = useNavigate();

    const [{ mealPeriods, loading: mealPeriodsLoading }, getMealPeriods] = useMealPeriods();

    const [{ plans, loading: plansLoading, numberOfPages: plansPages, total: plansTotal, }, getPlans] = usePlans({ params: { ...plansFilters } });

    const [{ recipes, loading: recipesLoading, numberOfPages: recipesPages, total: recipesTotal }, getRecipes] = useRecipes({ params: { ...recipesFilters } });

    const [data, setData] = useState({
        name: '',
        description: '',
        price: 0,
        images: [],
        comboPurposeId: '',
        categoryIds: [],
        recipes: [],
        plans: []
    });

    const [selectedDay, setSelectedDay] = useState(null);

    const [currentCategories, setCurrentCategories] = useState([]);

    const [{ comboPurposes, size, total, numberOfPages, error, loading }, getComboPurposes] = useComboPurposes();

    const [{ categories, loading: categoriesLoading, numberOfPages: categoriesPages }, getCategories] = useCategories({ params: { ...categoriesFilters } });

    const [{ data: createData, loading: createLoading }, create] = useAxios({ url: `/combos`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        setDisableAutoScroll(true);
    }, []);

    useEffect(() => {
        setRecipesFilters((oldRecipesFilters) => {
            return {
                ...oldRecipesFilters,
                page: 1
            }
        });
    }, [recipesFilters.name]);

    useEffect(() => {
        if (categories.length > 0) {
            setCurrentCategories((oldCurrentCategories) => {
                return [...oldCurrentCategories, ...categories]
            })
        }
    }, [categories]);

    useEffect(() => {
        setLoading?.({
            show: createLoading,
            message: `Creating`
        });
    }, [createLoading]);

    useEffect(() => {
        if (createData) {
            setCustomAlert(oldCustomAlert => {
                return {
                    ...oldCustomAlert,
                    severity: 'success',
                    message: `The Combo was successfully created.`,
                    show: true
                }
            });
            navigate('/combos');
        }
    }, [createData]);

    const handleSubmit = (e) => {
        e.preventDefault?.();

        swal({
            title: "¿are you sure?",
            text: "¿Please check that all information is complete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                onConfirm?.();
            } else {

            }
        });
    }

    const onConfirm = () => {
        const { images, categoryIds, plans, recipes, ...rest } = data;

        const formData = new FormData();

        Object.keys(rest).forEach((key, i) => {
            if (data[key]) {
                formData.append(key, data[key]);
            }
        });

        images.forEach((image, i) => {
            if (image) {
                formData.append(`images`, image, image.name);
            }
        });

        categoryIds.forEach((categoryId, i) => {
            formData.append(`categoryIds[${i}]`, categoryId);
        });

        plans.forEach((plan, i) => {
            formData.append(`planIds[${i}]`, plan?.id);
        });

        recipes.forEach((recipe, i) => {
            formData.append(`recipeIds[${i}]`, recipe?.id);
        });

        create({ data: formData });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const handleValue = (e) => {
        const value = data[e.target.name]?.includes(e.target.value);
        if (value) {
            const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
            setData((oldData) => {
                return {
                    ...oldData,
                    [e.target.name]: newValues
                }
            });
        } else {
            setData((oldData) => {
                return {
                    ...oldData,
                    [e.target.name]: [...data[e.target.name], e.target.value]
                }
            });
        }
    }

    const handleCategoriesEnd = () => {
        if (categoriesFilters?.page < categoriesPages) {
            setCategoriesFilters((oldCategoriesFilters) => {
                return {
                    ...oldCategoriesFilters,
                    page: oldCategoriesFilters?.page + 1
                }
            });
        }
    }

    const handleArrayChange = (e, index, arrayName) => {
        var newArrayValues = [];
        if (e.target.name === 'images') {
            newArrayValues = update(data?.[arrayName], { [index]: { $set: e.target.files[0] } });
        } else {
            newArrayValues = update(data?.[arrayName], { [index]: { [e.target.name]: { $set: e.target.type === 'file' ? e.target.files[0] : e.target.value } } });
        }
        setData((oldData) => {
            return {
                ...oldData,
                [arrayName]: newArrayValues
            }
        });
    }

    const handleAddImage = () => {
        setData((oldData) => {
            return {
                ...oldData,
                images: [...oldData.images, null]
            }
        })
    }

    const removeFromArray = (index, arrayName) => {
        data?.[arrayName]?.splice(index, 1);

        setData((oldData) => {
            return {
                ...oldData,
                [arrayName]: data?.[arrayName]
            }
        })
    }

    const handlePageChange = (page, filtername) => {
        if (page < 11 && page > 0 && filtername === 'recipes') {
            setRecipesFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            });
        }

        if (page < 11 && page > 0 && filtername === 'plans') {
            setPlansFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            });
        }
    }

    const handleSelectValue = (newValue, arrayName) => {
        const index = data[arrayName].findIndex(value => value?.id === newValue?.id);
        const value = data[arrayName][index];

        console.log(arrayName);

        if (!value) {
            setData((oldData) => {
                return {
                    ...oldData,
                    [arrayName]: [...data?.[arrayName], newValue]
                }
            })
        } else {
            data[arrayName].splice(index, 1);
            setData((oldData) => {
                return {
                    ...oldData,
                    [arrayName]: [...data?.[arrayName]]
                }
            })
        }
    }

    return (
        <div className="card" style={{ width: '100%', marginBottom: 200 }}>
            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-6">
                                <label>
                                    <h5>
                                        Combo Title
                                    </h5>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    autoFocus
                                    value={data?.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>
                                    <h5>
                                        Price ($)
                                    </h5>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="example: 30"
                                    name="price"
                                    value={data?.price}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <label className="mb-1">
                                    <h5>Categories</h5>
                                </label>
                                <InfiniteSelectList
                                    items={currentCategories}
                                    emptyMessage="No se encontraron categorias."
                                    childrensSourcePath={`/categories`}
                                    itemGetParam={`id`}
                                    childrenGetParamName={`parentId`}
                                    onReachEnd={handleCategoriesEnd}
                                    selectedValues={data?.categoryIds}
                                    labelAccessor={'name'}
                                    keyValue={'id'}
                                    name="categoryIds"
                                    onSelectValue={handleValue}
                                    loadingMessage={'Loading...'}
                                    loading={categoriesLoading}
                                />
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <label>
                                    <h5>Purpose</h5>
                                </label>
                                <select name="comboPurposeId" value={data?.comboPurposeId} onChange={handleChange} className="form-control">
                                    <option value="">
                                        Seleccione un valor
                                    </option>
                                    {
                                        comboPurposes?.map((purpose, i) => {
                                            return (
                                                <option key={i} value={purpose?.id}>{purpose?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-12 mb-4">
                                <div>
                                    <label>
                                        Description
                                    </label>
                                    <textarea name="description" onChange={handleChange} value={data?.description} className="form-control" style={{ height: 120 }} rows={8}></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4 pb-2" style={{ borderBottom: '1px solid' }}>
                                <h3>
                                    📸 Images
                                </h3>
                                <div className="row align-items-center">
                                    {
                                        data?.images?.map((image, i) => {
                                            return (
                                                <div className="col-md-4 my-4 text-center" key={i}>
                                                    <ImgUploadInput
                                                        description="drag or click"
                                                        name="images"
                                                        change={(e) => { handleArrayChange(e, i, 'images') }}
                                                    />
                                                    <button type="button" onClick={() => { removeFromArray(i, 'images') }} className="btn btn-xs btn-danger mt-1">
                                                        Remove
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="col-md-4">
                                        <button onClick={handleAddImage} type="button" className="btn btn-primary">
                                            Add Image
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4 pb-2">
                                <h3>
                                    Elements
                                </h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5 className="text-center">Plans</h5>
                                        <div className="row mb-4">
                                            {
                                                data?.plans?.map((plan, i) => {
                                                    return (
                                                        <button onClick={() => { handleSelectValue(plan, 'plans') }} type="button" key={i} className="col-md-3 btn btn-light mx-2 p-3 rounded">
                                                            {plan?.name}
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="text-center">
                                            <button type="button" className="btn btn-primary" onClick={() => setShowPlans(true)}>
                                                Add Plan
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <h5 className="text-center">Recipes</h5>
                                        <div className="row mb-4">
                                            {
                                                data?.recipes?.map((recipe, i) => {
                                                    return (
                                                        <button onClick={() => { handleSelectValue(recipe, 'recipes') }} type="button" key={i} className="col-md-3 btn btn-light mx-2 p-3 rounded">
                                                            {recipe?.name}
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="text-center">
                                            <button type="button" className="btn btn-primary" onClick={() => setShowRecipes(true)}>
                                                Add recipe
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Back
                            </Link>
                            <button type="submit" className="btn btn-primary mx-2">
                                Create
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal size="lg" className="fade" show={showRecipes}>
                <Modal.Header>
                    <Modal.Title>Add Recipe:</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setShowRecipes(false)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <CustomTable
                        onSelectValue={(value) => { handleSelectValue(value, 'recipes') }}
                        loading={plansLoading}
                        withoutGlobalActions
                        variant="simple"
                        hideSelectAll
                        selectedValues={data?.recipes?.map(plan => plan?.id)}
                        total={recipesTotal}
                        values={recipes}
                        currentPage={recipesFilters.page}
                        collumns={RecipesModalColumns}
                        changePage={(value) => handlePageChange(value, 'recipes')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setShowRecipes(false)}
                        variant="danger light"
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" className="fade" show={showPlans}>
                <Modal.Header>
                    <Modal.Title>Add Plan:</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setShowPlans(false)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <CustomTable
                        onSelectValue={(value) => { handleSelectValue(value, 'plans') }}
                        loading={plansLoading}
                        withoutGlobalActions
                        variant="simple"
                        hideSelectAll
                        selectedValues={data?.plans?.map(plan => plan?.id)}
                        total={plansTotal}
                        values={plans}
                        currentPage={plansFilters.page}
                        collumns={ShortPlansColumns}
                        changePage={(value) => handlePageChange(value, 'plans')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setShowPlans(false)}
                        variant="danger light"
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default CombosCreate;