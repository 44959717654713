import { Link } from "react-router-dom";
import CutText from "../util/CutText";

const RenderCommentable = ({ value }) => {

    var urlTo = '';
    
    if (value?.commentableType === 'recipe') {
        urlTo = `/recipes/${value?.commentable?.id}`;
    }

    if (value?.commentableType === 'plan') {
        urlTo = `/plans/${value?.commentable?.id}`;
    }

    if (value?.commentableType === 'combo') {
        urlTo = `/combos/${value?.commentable?.id}`;
    }

    return (
        <div>
            <Link to={urlTo}>
                <CutText text={value?.commentable?.name} length={25} />
            </Link>
        </div>
    )
}

export default RenderCommentable;