import ActionDropdown from "../../ActionDropdown";
import ImageAndName from "../../ImageAndName";
import TableCheck from "../TableCheck";

const RecipesModalColumns = [
    {
        Label: () => 'check',
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Name',
        Component: ImageAndName
    },
    {
        Label: () => 'Categories',
        Component: ({ value }) => value?.categories?.map(category => category?.name)?.join?.(', ')
    },
    {
        Label: () => 'Difficulty',
        Component: ({ value }) => value?.recipeDifficulty?.name
    },
    {
        Label: () => 'Price',
        Component: ({ value }) => value?.price && value?.isPremium ? `${value?.price}$` : 'Free'
    },
    {
        Label: () => 'Preparation Time  ⏰',
        Component: ({ value }) => value?.preparationTime ? `${value?.preparationTime}min` : '--'
    },
    {
        Label: () => 'Type',
        Component: ({ value }) => value?.mealPeriods?.map?.(period => period?.name)?.join(', ')
    },
];

export default RecipesModalColumns;

