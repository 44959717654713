import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import imgUrl from "../helpers/imgUrl";
import useSellersRatings from "../hooks/useSellersRatings";
import RatingComponent from "./RatingComponent";

const SellerSummaryCard = () => {

    const { user } = useAuth();

    const [filters, setFilters] = useState({
        page: 1,
        orderBy: 'createdAt,DESC',
        sellerId: user?.sellerId
    });

    const [currentReviews, setCurrentReviews] = useState([]);

    const [showCustomersReviews, setShowCustomersReviews] = useState(false);

    const [{ sellersRatings, numberOfPages: ratingPages, total: totalReviews, loading: loadingRatings }, getRatings] = useSellersRatings({ params: { ...filters }, options: { useCache: false } });

    useEffect(() => {
        if (sellersRatings?.length > 0) {
            setCurrentReviews((oldReviews) => {
                return [...oldReviews, ...sellersRatings];
            });
        }
    }, [sellersRatings]);


    const handleMore = () => {
        if (ratingPages > filters?.page) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: oldFilters?.page + 1
                }
            });
        }
    }

    return (
        <>
            <div className="card">
                <div className="text-end card-body">
                    <button className="btn btn-warning" onClick={() => setShowCustomersReviews(true)}>
                        Show Reviews ({totalReviews})
                    </button>
                </div>
            </div>


            <Modal show={showCustomersReviews} onClose={() => setShowCustomersReviews(false)}>
                <Modal.Header>
                    <Modal.Title>Customers Reviews ({totalReviews})</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setShowCustomersReviews(false)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {
                        currentReviews?.length > 0 ?
                            currentReviews?.map((review, i) => {
                                return (
                                    <div key={i} className="py-1 border-bottom border-main">
                                        <div className="d-flex align-items-center">
                                            <img src={imgUrl(review?.client?.imgPath)} style={{ height: 50, width: 50 }} className="rounded-full" />
                                            <p style={{ margin: 0 }} className="mx-1 font-bold">
                                                {review?.client?.name}
                                                <RatingComponent
                                                    disabled
                                                    value={review?.value}
                                                    size="sm"
                                                />
                                            </p>
                                        </div>
                                        <p className="text-gray-500">
                                            {review?.comment}
                                        </p>
                                    </div>
                                )
                            })
                            :
                            <h3 className="text-center text-danger">
                                No results found.
                            </h3>
                    }
                    {
                        loadingRatings ?
                            <div className="text-center my-4">
                                Loading more reviews...
                            </div>
                            :
                            ratingPages > filters?.page ?
                                <div className="text-center">
                                    <button onClick={handleMore} className="bg-white px-8 py-2 rounded-full shadow mt-4 mb-4">
                                        Load More
                                    </button>
                                </div>
                                :
                                <div className="text-center my-4">
                                    No more reviews.
                                </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SellerSummaryCard;