import SystemInfo from "../util/SystemInfo";

const ImageAndName = ({ nameValue, imgValue, value }) => {
    return (
        <div className="text-center d-flex align-items-center">
            {
                value?.images?.length > 0 ?
                    <img
                        src={`${SystemInfo?.api}${value?.images?.[0]?.path}`}
                        className="rounded-lg"
                        width="36"
                        height={"36"}
                        alt="image"
                    />
                    :
                    null
            }
            {
                imgValue || value?.sellerProfile?.logo || value?.appLogo ?
                    <img
                        src={`${SystemInfo?.api}${value?.sellerProfile?.logo ? value?.sellerProfile?.logo : value?.appLogo ? value?.appLogo : imgValue}`}
                        className="rounded-lg"
                        width="36"
                        height={"36"}
                        alt="image"
                    />
                    :
                    null
            }
            <span className="w-space-no mx-1">{nameValue ? nameValue : ''}</span>
        </div>
    )
}

export default ImageAndName;