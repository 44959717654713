import { Link } from "react-router-dom";
import DateFormatter from "../../DateFormatter";

const ChatsColumns = [
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Client',
        Component: ({ value }) => value.client?.name,
    },
    {
        Label: () => 'Last message',
        Component: ({ value }) => value.messages[0]?.content,
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat="dd-MM-yyyy" />
    },
    {
        Label: () => 'Actions',
        Component: ({ value }) => <Link to={`/chats/${value.id}`}> Show Conversation</Link>
    }
];

export default ChatsColumns;

