import { forwardRef, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import InfiniteSelectList from "./InfiniteSelectList";

const InfiniteSelectListItem = forwardRef(({
    value,
    childrensSourcePath,
    itemGetParam,
    childrenGetParamName,
    selectedValues = [],
    labelAccessor,
    name,
    onSelect,
    keyValue,
    emptyMessage,
    loadingMessage,
    deep,
    withoutDropButton
}, ref) => {

    const [filters, setFilters] = useState({
        page: 1,
        [childrenGetParamName]: value[itemGetParam]
    });

    const [currentChildrens, setCurrentChildrens] = useState([]);

    const [showChildrens, setShowChildrens] = useState(false);

    const [{ data, loading }, getChildrens] = useAxios({ url: `${childrensSourcePath}`, params: filters }, { manual: true, useCache: false });

    useEffect(() => {
        setCurrentChildrens([]);
        if (showChildrens) {
            getChildrens();
        }
    }, [showChildrens])

    useEffect(() => {
        if (data) {
            setCurrentChildrens((oldChildrens) => {
                return [...oldChildrens, ...data?.results]
            })
            console.log(data);
        }
    }, [data]);

    const handleChange = (e) => {
        onSelect?.({
            target: {
                name: name,
                value: value[keyValue]
            }
        });
    }

    const handleToggleShowChildrens = () => {
        setShowChildrens((oldShowChildrens) => !oldShowChildrens);
    }

    const handleEnd = () => {
        if (filters?.page < data?.numberOfPages) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: oldFilters?.page + 1
                }
            });
        }
    }

    return <li ref={ref}>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', margin: '5px 0', textTransform: 'capitalize' }}>
            <input type={'checkbox'} id={`${name}-${value[keyValue]}`} checked={selectedValues.includes(value[keyValue])} onChange={handleChange} />
            <label style={{ margin: '0 10px' }} htmlFor={`${name}-${value[keyValue]}`}>{value[labelAccessor]}</label>
            {
                !withoutDropButton &&
                <button type="button" className="btn btn-sm" onClick={handleToggleShowChildrens}>
                    {
                        showChildrens ?
                            <i className="flaticon-381-arrow-up-squared"></i>
                            :
                            <i className="flaticon-381-arrow-down-squared"></i>
                    }
                </button>
            }
        </div>
        {
            showChildrens ?
                !loading ?
                    currentChildrens?.length > 0 ?
                        <InfiniteSelectList
                            items={currentChildrens}
                            childrensSourcePath={childrensSourcePath}
                            itemGetParam={itemGetParam}
                            childrenGetParamName={childrenGetParamName}
                            onReachEnd={handleEnd}
                            selectedValues={selectedValues}
                            labelAccessor={labelAccessor}
                            keyValue={keyValue}
                            name={name}
                            onSelectValue={onSelect}
                            emptyMessage={emptyMessage}
                            deep={deep + 1}
                            withoutDropButton={withoutDropButton}
                        />
                        :
                        emptyMessage
                    :
                    loadingMessage
                :
                null
        }
    </li >
});

export default InfiniteSelectListItem;