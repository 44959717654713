import clsx from "clsx";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import useNotificationTypes from "../../hooks/useNotificationTypes";

const NotificationsForm = () => {

    const [filters, setFilters] = useState({
        page: 1,
        perPage: 100,
        role: 'SELLER'
    });

    const [currentNotificationsTypes, setCurrentNotificationsTypes] = useState([]);

    const [showResponseMessage, setShowResponseMessage] = useState(false);

    const [{ notificationTypes, total, numberOfPages, size, error, loading }, getNotificationTypes] = useNotificationTypes({ params: { ...filters }, options: { useCache: false } });

    const [{ loading: saveLoading }, toggleActiveNotificationType] = useAxios({ url: `/notification-types/configure`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (showResponseMessage) {
            setTimeout(() => {
                setShowResponseMessage(false);
            }, 3000)
        }
    }, [showResponseMessage])

    useEffect(() => {
        if (notificationTypes?.length > 0) {
            const selectedTypes = [];

            notificationTypes?.forEach(notificationType => {
                if (notificationType?.isActive) {
                    return selectedTypes?.push(notificationType?.code);
                }
            });

            setCurrentNotificationsTypes(selectedTypes);
        }
    }, [notificationTypes]);

    const handleSaveNotifications = () => {
        toggleActiveNotificationType({
            data: {
                notificationTypeCodes: currentNotificationsTypes
            }
        }).then(() => {
            setShowResponseMessage({
                show: true,
                message: 'Saved',
                severity: 'success'
            });
        }).catch(() => {
            setShowResponseMessage({
                show: true,
                message: 'An error has occurred',
                severity: 'error'
            });
        });
    }

    const handleChange = (anotherValue) => {
        const value = currentNotificationsTypes?.includes(anotherValue);
        if (value) {
            const newValues = currentNotificationsTypes?.filter(n => n !== anotherValue);
            setCurrentNotificationsTypes(newValues);
        } else {
            setCurrentNotificationsTypes((oldData) => {
                return [...oldData, anotherValue]
            });
        }
    }

    return (
        <div className="card p-4">
            <div className="card-header">
                <h4>
                    Configure Notifications
                </h4>
            </div>
            <div className="card-body">
                <div className="row">
                    {
                        notificationTypes?.map((notificationType, i) => {
                            return (
                                <div className="col-md-3" key={i}>
                                    <label htmlFor={`notification-type-${notificationType?.code}`}>
                                        <input
                                            type="checkbox"
                                            checked={currentNotificationsTypes?.includes(notificationType?.code)}
                                            onChange={() => handleChange?.(notificationType?.code)}
                                            id={`notification-type-${notificationType?.code}`}
                                        />
                                        <span className="mx-2">
                                            {
                                                notificationType?.name
                                            }
                                        </span>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-end card-footer">
                {
                    showResponseMessage?.show &&
                    <div className={clsx("animate__animated animate__fadeInLeft mx-4", {
                        "text-success": showResponseMessage?.severity === 'success',
                        "text-danger": showResponseMessage?.severity === 'error'
                    })}>
                        {
                            showResponseMessage?.message
                        }
                    </div>
                }
                <button disabled={saveLoading} onClick={handleSaveNotifications} className="btn btn-primary">
                    {
                        saveLoading ?
                            'loading'
                            :
                            'Change'
                    }
                </button>
            </div>
        </div>
    )
}
export default NotificationsForm;