import { useState } from "react";
import ChatsColumns from "../../../components/CustomTable/Columns/ChatsColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import useChats from "../../../hooks/useChats";

const Chats = () => {
    const [filters, setFilters] = useState({
        page: 1,
        orderBy: 'createdAt,DESC'
    });

    const [{ chats, total, numberOfPages, loading }] = useChats({ params: { ...filters } });

    const handlePageChange = (page) => {
        if (page < 11 && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    return <div>
        <CustomTable
            loading={loading}
            title={'Chats'}
            pages={numberOfPages}
            total={total}
            values={chats}
            currentPage={filters.page}
            collumns={ChatsColumns}
            changePage={handlePageChange}
        />
    </div>
}

export default Chats;