import ActionDropdown from "../../ActionDropdown";
import ImageAndName from "../../ImageAndName";
import TableCheck from "../TableCheck";
import { format } from "date-fns";
import RatingComponent from "../../RatingComponent";
import DateFormatter from "../../DateFormatter";
import RenderRatingProduct from "../../RenderRatingProduct";

const RatingsColumns = [
    {
        Label: TableCheck,
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Client',
        Component: ({ value }) => <ImageAndName imgValue={value?.client?.imgPath} nameValue={value?.client?.name} />
    },
    {
        Label: () => 'Product',
        Component: ({ value }) => <RenderRatingProduct value={value} />
    },
    {
        Label: () => 'Rating',
        Component: ({ value }) => <RatingComponent value={value?.value} disabled size="sm" />
    },
    {
        Label: () => 'comment',
        accessor: 'comment'
    },
    {
        Label: () => 'Created At',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} />
    }
];

export default RatingsColumns;

