import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgUploadInput from "../../../components/ImgUploadInput";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import SystemInfo from "../../../util/SystemInfo";

const CustomersDetails = () => {

    const { id } = useParams();

    const { setLoading, setCustomAlert } = useFeedBack();

    const navigate = useNavigate();

    const [data, setData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        image: null,
        userStatusCode: ''
    });

    const [imagePreview, setImagePreview] = useState('');

    const [{ data: dataToUpdate, loading: dataToUpdateLoading }, getdataToUpdate] = useAxios({ url: `/clients/${id}` }, { useCache: false });

    useEffect(() => {
        if (dataToUpdate) {
            const { imgPath, role, userStatus, phoneNumber, address, ...rest } = dataToUpdate;

            setData((oldData) => {
                return {
                    ...oldData,
                    ...rest,
                    phoneNumber: phoneNumber || '',
                    userStatusCode: userStatus?.code || ''
                }
            });

            if (imgPath) {
                setImagePreview(`${SystemInfo.api}${imgPath}`);
            }
        }
    }, [dataToUpdate]);

    useEffect(() => {
        setLoading?.({
            show: dataToUpdateLoading,
            message: `Loading`
        });
    }, [dataToUpdateLoading])

    const handleSubmit = (e) => {
        e.preventDefault?.();

        const formData = new FormData();

        const { id, ...rest } = data;

        Object.keys(rest).forEach((key, i) => {
            if (data[key]) {
                if (key === 'image') {
                    formData.append(key, data[key], data[key].name);
                } else {
                    formData.append(key, data[key]);
                }
            }
        });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    return (
        <>
            <div className="card" style={{ width: '100%' }}>
                <div className="card-body">
                    <div className="basic-form">
                        <div className="col-md-12 form-group mb-3">
                            <label><h5>Estatus</h5></label>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-8">
                                    <div className="mb-4">
                                        <label>
                                            <h5>
                                                Name
                                            </h5>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            name="name"
                                            autoFocus
                                            value={data?.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            value={data?.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label>
                                            Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="phone number..."
                                            name="phoneNumber"
                                            value={data?.phoneNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>
                                        <h5>
                                            Profile Image
                                        </h5>
                                    </label>
                                    <ImgUploadInput
                                        previewImage={imagePreview}
                                        style={{ width: '65%' }}
                                        description="Profile Image"
                                        name="image"
                                        change={handleChange}
                                    />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label>
                                        Estado
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Back
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CustomersDetails;