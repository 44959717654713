import clsx from "clsx";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import imgUrl from "../../../helpers/imgUrl";
import useAxios from "../../../hooks/useAxios";
import './ChatsDetails.css';

const ChatsDetails = () => {
    const { id } = useParams();

    const { user } = useAuth();

    const [message, setMessage] = useState('');

    const [attachments, setAttachments] = useState([]);

    const [pages, setPages] = useState('');

    const [filters, setFilters] = useState({
        page: 1,
        orderBy: 'createdAt,DESC'
    });

    const [currentMessages, setCurrentMessages] = useState([]);

    const [{ data: newMessage, loading: newMessageLoading }, sendMessage] = useAxios({ url: `/chats/${id}/messages`, method: 'POST' }, { manual: true, useCache: false });

    const [{ data, loading, error }, getChat] = useAxios({ url: `/chats/${id}` }, { useCache: false });

    const [{ data: chatMessages, loading: chatMessagesLoading }, getChatMessages] = useAxios({ url: `/chats/${id}/messages`, params: { ...filters } }, { useCache: false });

    useEffect(() => {
        if (chatMessages?.results?.length > 0) {
            setCurrentMessages((oldCurrentMessages) => {
                return [...oldCurrentMessages, ...chatMessages?.results]
            })
        }

        if (chatMessages) {
            setPages(chatMessages?.numberOfPages);
        }
    }, [chatMessages]);

    useEffect(() => {
        if (newMessage) {
            setCurrentMessages((oldMessages) => {
                return [newMessage, ...oldMessages]
            });
            setMessage('');
            setAttachments([]);
        }
    }, [newMessage]);

    const handleSubmit = (e) => {
        e?.preventDefault?.();
        if (newMessageLoading || !message) {
            return;
        }

        const data = new FormData();

        data.append('content', message);

        attachments.forEach((attachment, i) => {
            data.append(`attachments`, attachment, attachment.name);
        });

        sendMessage({ data });
    }

    const handleMore = () => {
        if (pages > filters?.page) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: oldFilters?.page + 1
                }
            });
        }
    }

    const handleAttachments = (e) => {
        setAttachments((oldAttachments) => {
            return [...oldAttachments, ...e.target.files];
        });
    }

    const removeFromArray = (index) => {
        attachments?.splice(index, 1);

        setAttachments((olddata) => {
            return [...attachments];
        });
    }

    return <div
        className="ChatsDetails"
        style={{ height: window.screen.height - 60 }}
    >
        <div className="ChatsDetails__heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <img src={imgUrl(data?.client?.imgPath)} alt="" className="ChatsDetails__heading-img" />
                <span className="ChatsDetails__heading-name">{data?.client.name}</span>
            </div>


            <Link to={"/chats"} className="btn btn-primary">
                Back
            </Link>
        </div>

        <div className="ChatsDetails__messages-list">
            {currentMessages?.map(message => {
                const sentByMe = message.userId == user.id;

                return <div
                    key={message.id}
                    className={clsx('ChatsDetails__message', sentByMe && 'ChatsDetails__message--sent-by-me')}
                >
                    <img
                        className="ChatsDetails__message-img"
                        src={imgUrl(sentByMe ? data?.seller?.logo : data?.client?.imgPath)}
                        alt=""
                    />
                    <p className="ChatsDetails__message-content">
                        {
                            message?.attachments?.length > 0 &&
                            <div>
                                {
                                    message?.attachments?.map((attachment, i2) => {
                                        return (
                                            <a href={imgUrl(attachment.path)} download target="_blank" className="mb-1 p-2 d-block" style={{ backgroundColor: `rgba(255,255,255, .3)` }}>
                                                {attachment.name}
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        }
                        {message.content}
                    </p>
                </div>
            })}
            {
                chatMessagesLoading ?
                    <div className="text-center my-2">
                        Loading more messages...
                    </div>
                    :
                    pages > filters?.page ?
                        <div className="text-center">
                            <button onClick={handleMore} className="btn btn-light my-2">
                                Load More
                            </button>
                        </div>
                        :
                        <div className="text-center my-2">
                            No more messages.
                        </div>
            }
        </div>
        <form className="ChatsDetails__form" onSubmit={handleSubmit}>
            {
                attachments.length > 0 &&
                <div className="d-flex align-items-center my-2">
                    {
                        attachments.map((file, i) => {
                            return (
                                <div className="position-relative mx-2">
                                    <label onClick={() => removeFromArray(i)} type="button" style={{ right: -8, top: -8, height: 20, width: 20, borderRadius: 5 }} className="bg-danger position-absolute d-flex align-items-center justify-content-center text-white">
                                        X
                                    </label>
                                    <img src={URL.createObjectURL(file)} style={{ height: 60, width: 60 }} />
                                </div>
                            )
                        })
                    }
                </div>
            }
            <div className="d-flex align-items-center" style={{ borderBottom: '1px solid gray' }}>
                <i className="flaticon-381-dashboard"></i>
                <input
                    type="text"
                    className="ChatsDetails__form-input"
                    placeholder="Write your message..."
                    name="content"
                    onChange={(e) => setMessage(e?.target?.value)}
                    value={message}
                />
                <label className="btn btn-xs btn-warning mr-2">
                    <i className="flaticon-381-paperclip"></i>
                    <input multiple onChange={handleAttachments} type="file" id="files" style={{ display: 'none' }} accept="image/png, image/jpeg" />
                </label>
                <button
                    type="submit"
                    className="btn btn-primary"
                >
                    <i className="flaticon-381-send-1"></i>
                </button>
            </div>
        </form>
    </div>;
}

export default ChatsDetails;