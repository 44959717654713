import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DateFormatter from "../../../components/DateFormatter";
import useAxios from "../../../hooks/useAxios";

const CommentsDetails = () => {

    const { id } = useParams();

    const [{ data, loading }, getComment] = useAxios({ url: `/comments/${id}` }, { useCache: false });

    const [{ data: answerData, loading: answerLoading }, createAnswer] = useAxios({ url: `/comments/${id}/answer`, method: 'put' }, { manual: true, useCache: false });

    const [currentComment, setCurrentComment] = useState();

    const [response, setResponse] = useState('');

    useEffect(() => {
        if (answerData) {
            const { commentable, commentableType, ...rest } = answerData;
            setCurrentComment((oldCurrentComment) => {
                return {
                    ...oldCurrentComment,
                    ...rest
                }
            })
        }
    }, [answerData])

    useEffect(() => {
        if (data) {
            setCurrentComment(data);
        }
    }, [data]);

    const getCommentableUrl = (type) => {
        if (type === 'recipe') {
            return `/recipes/${currentComment?.commentable?.id}`;
        }

        if (type === 'plan') {
            return `/plans/${currentComment?.commentable?.id}`;
        }

        if (type === 'combo') {
            return `/combos/${currentComment?.commentable?.id}`;
        }
    }

    const handleSubmit = (e) => {
        e?.preventDefault?.();
        if (!response) {
            alert('The response is required.');
            return;
        }

        createAnswer({
            data: {
                answer: response
            }
        })
    }

    return (
        <div>
            <div className="text-end mb-3">
                <Link to='/comments' className="btn btn-primary">
                    Back to the List
                </Link>
            </div>
            <div className="card" style={{ minHeight: '60vh' }}>
                {
                    loading ?
                        <h4 className="m-auto">
                            Loading...
                        </h4>
                        :
                        <div className="p-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>
                                        Comment: #{currentComment?.id}
                                    </h4>
                                    <div>
                                        {currentComment?.comment} - <b>{currentComment?.name || 'Guest'}</b> <DateFormatter value={currentComment?.createdAt} dateFormat={'dd/MM/yyyy hh:mm:ss'} />.
                                    </div>
                                    <br />
                                    <br />
                                    <h4>
                                        Your answer
                                    </h4>
                                    {
                                        currentComment?.answer ?
                                            <div className="animate__animated animate__fadeInLeft">
                                                {currentComment?.answer} - <b>You</b> <DateFormatter value={currentComment?.answeredAt} dateFormat={'dd/MM/yyyy hh:mm:ss'} />.
                                            </div>
                                            :
                                            <form onSubmit={handleSubmit} className="text-end">
                                                <textarea
                                                    style={{ height: '100px' }}
                                                    className="form-control"
                                                    rows={4}
                                                    placeholder="write your answer..."
                                                    value={response}
                                                    onChange={(e) => setResponse(e.target.value)}
                                                />
                                                <br />
                                                <button disabled={answerLoading} className="btn btn-sm btn-primary">
                                                    {
                                                        answerLoading ?
                                                            'Loading'
                                                            :
                                                            'Submit'
                                                    }
                                                </button>
                                            </form>
                                    }
                                </div>
                                <div className="col-md-6 text-center">
                                    <h3 className="text-capitalize">
                                        {currentComment?.commentableType}
                                    </h3>
                                    <p className="text-capitalize">
                                        {currentComment?.commentable?.name}
                                    </p>
                                    <a href={getCommentableUrl(currentComment?.commentableType)} className="btn btn-primary">
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default CommentsDetails;