import Chats from "../pages/private/chats/Chats";
import ChatsDetails from "../pages/private/chats/ChatsDetails";
import Combos from "../pages/private/combos/Combos";
import CombosCreate from "../pages/private/combos/CombosCreate";
import CombosEdit from "../pages/private/combos/CombosEdit";
import Comments from "../pages/private/comments/Comments";
import CommentsDetails from "../pages/private/comments/CommentsDetails";
import Customers from "../pages/private/customers/Customers";
import CustomersDetails from "../pages/private/customers/CustomersDetails";
import Dashboard from "../pages/private/Dashboard";
import MyAccount from "../pages/private/MyAccount";
import Orders from "../pages/private/orders/Orders";
import OrdersDetails from "../pages/private/orders/OrdersDetails";
import Plans from "../pages/private/plans/Plans";
import PlansCreate from "../pages/private/plans/PlansCreate";
import PlansEdit from "../pages/private/plans/PlansEdit";
import Ratings from "../pages/private/ratings/Ratings";
import Recipes from "../pages/private/recipes/Recipes";
import RecipesCreate from "../pages/private/recipes/RecipesCreate";
import RecipesEdit from "../pages/private/recipes/RecipesEdit";

const createLink = (
    title,
    hidden,
    component,
    icon,
    path,
    permissions,
    children
) => ({ title, hidden, component, icon, path, permissions, children });

const MenuLinks = [
    createLink('DashBoard', null, <Dashboard />, 'flaticon-381-dashboard', '/dashboard', null),

    createLink('My Account', true, <MyAccount />, 'flaticon-381-dashboard', '/my-account', null),

    createLink('Customers', null, <Customers />, 'flaticon-381-user-9', '/customers', null),
    createLink('Customers Details', true, <CustomersDetails />, 'flaticon-381-user-9', '/customers/:id', null),

    createLink('Comments', null, <Comments />, 'flaticon-381-list', '/comments', null),
    createLink('Comments Details', true, <CommentsDetails />, 'flaticon-381-list', '/comments/:id', null),

    createLink('Chats', null, <Chats />, 'flaticon-381-list', '/chats', null),
    createLink('Chat Details', true, <ChatsDetails />, 'flaticon-381-list', '/chats/:id', null),

    createLink('Orders', null, <Orders />, 'flaticon-381-list', '/orders', null),
    createLink('Orders Details', true, <OrdersDetails />, 'flaticon-381-list', '/orders/:id', null),

    createLink('Recipes', null, <Recipes />, 'flaticon-381-list', '/recipes', null, [
        createLink('List', null, <Recipes />, 'flaticon-381-user-9', '/recipes', null),
        createLink('Create', null, <RecipesCreate />, 'flaticon-381-user-9', '/recipes/create', null),
        createLink('Recipes Edit', true, <RecipesEdit />, 'flaticon-381-user-9', '/recipes/:id', null),
    ]),

    createLink('Plans', null, <Plans />, 'flaticon-381-list', '/plans', null, [
        createLink('List', null, <Plans />, 'flaticon-381-user-9', '/plans', null),
        createLink('Create', null, <PlansCreate />, 'flaticon-381-user-9', '/plans/create', null),
        createLink('Plans Edit', true, <PlansEdit />, 'flaticon-381-user-9', '/plans/:id', null),
    ]),

    createLink('Combos', null, <Combos />, 'flaticon-381-list', '/combos', null, [
        createLink('List', null, <Combos />, 'flaticon-381-user-9', '/combos', null),
        createLink('Create', null, <CombosCreate />, 'flaticon-381-user-9', '/combos/create', null),
        createLink('Combos Edit', true, <CombosEdit />, 'flaticon-381-user-9', '/combos/:id', null),
    ]),

    createLink('Ratings', null, <Ratings />, 'flaticon-381-list', '/ratings', null)
];

export default MenuLinks;