import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import {
    ORDER_CREATED,
    SELLER_REGISTERED,
    COMMENT_CREATED,
    COMMENT_ANSWERED,
    PRODUCT_RATED,
} from "../../util/NotificationsTypes";
import { Link } from "react-router-dom";

const NotificationRow = ({ notification, onReadNotification }) => {

    const [currentNotification, setCurrentNotification] = useState(null);

    const [{ }, setReadNotification] = useAxios({ url: `/notifications/${currentNotification?.id}/mark-as-read`, method: 'put' }, { manual: true, useCache: false });

    useEffect(() => {
        if (notification) {
            setCurrentNotification(notification);
        }
    }, [notification]);

    var notificationUrl = '#';

    switch (notification?.type) {
        case ORDER_CREATED:
            notificationUrl = `/orders/${notification?.additionalData?.orderId}`
            break;
        case COMMENT_CREATED || COMMENT_ANSWERED:
            notificationUrl = `/comments/${notification?.additionalData?.commentId}`
            break;
        case PRODUCT_RATED:
            notificationUrl = `/recipes/${notification?.additionalData?.productType}s/${notification?.additionalData?.productId}`
            break;

    }

    const timeDistance = (date, length) => {
        if (date) {
            const dateDistance = formatDistance(new Date(date), new Date());

            if (dateDistance?.length > length) {
                return `${dateDistance?.slice(0, length)}...`
            }

            return dateDistance;
        }
    }

    const handleClick = () => {
        if (!currentNotification?.readAt) {
            setCurrentNotification((oldNotification) => {
                return {
                    ...oldNotification,
                    readAt: true
                }
            });
            onReadNotification?.();
            setReadNotification();
        }
    }

    return (
        <li onClick={handleClick} title={`${currentNotification?.message}.`}>
            <Link to={notificationUrl} >
                <div className="timeline-panel">
                    <div className="media-body">
                        <h6 className="mb-1">
                            {currentNotification?.message?.length > 30 ?
                                `${currentNotification?.message?.slice(0, 30)}...`
                                :
                                currentNotification?.message
                            }
                        </h6>
                        <small className="d-block">
                            <b>{timeDistance(currentNotification?.createdAt, 20)} ago</b>
                        </small>
                    </div>
                    {
                        !currentNotification?.readAt &&
                        <small className="float-right text-muted pl-2 text-capitalize">
                            <b>Nueva</b>
                        </small>
                    }
                </div>
            </Link>
        </li>
    )
}

export default NotificationRow;